import React from 'react'

interface PrivacyStatementProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Privacy
  location: any
}

const PrivacyStatement: React.FC<PrivacyStatementProps> = ({ location }) => (
  <section>
    <div className="container py-5 my-lg-5">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          {location.hostname !== 'localhost' ? (
            <script
              id="CookieDeclaration"
              src="https://consent.cookiebot.com/4ad07af0-694d-4f9c-bd4e-657f1afd128d/cd.js"
              type="text/javascript"
              async
            />
          ) : (
            <h2>Cookies niet beschikbaar in lokale omgeving...</h2>
          )}
        </div>
      </div>
    </div>
  </section>
)

export default PrivacyStatement
